import React from 'react';
import './styles.css';

const Footer = () => {
  return (
    <footer className="footer">
      <p>Made with 🤔 by <a href="https://x.com/FilmLifestyle" target="_blank" rel="noopener noreferrer">Matt Crawford</a></p>
    </footer>
  );
};

export default Footer;
