const quotes = [
    "It’s better to avoid stupidity than try to be very intelligent.",
    "You don’t have to be brilliant, only a little bit wiser than the other guys, on average, for a long time.",
    "A lot of people with high IQs are terrible investors because they’ve got terrible temperaments.",
    "The world is not driven by greed, it’s driven by envy.",
    "I think that, every time you see the word EBITDA, you should substitute the word ‘bullshit’ earnings.",
    "If you don’t keep learning, other people will pass you by.",
    "The best armor of old age is a well-spent life preceding it.",
    "The habit of committing far more time to learning and thinking than to doing is no accident.",
    "I did not succeed in life by intelligence. I succeeded because I have a long attention span.",
    "If you don't allow for self-serving bias in the conduct of others, you are, again, a fool.",
    "There’s no way that you can live an adequate life without many mistakes.",
    "The number one idea is to view a stock as an ownership of the business and to judge the staying quality of the business in terms of its competitive advantage.",
    "It's better to be roughly right than precisely wrong.",
    "Invert, always invert.",
    "The best way to teach is through example.",
    "No wise pilot, no matter how great his talent and experience, fails to use his checklist.",
    "We have three baskets for investing: yes, no, and too tough to understand.",
    "The iron rule of nature is: you get what you reward for.",
    "If you want ants to come, you put sugar on the floor.",
    "Life will have terrible blows, horrible blows, unfair blows, doesn’t matter. Some people recover and others don’t.",
    "The safest way to get what you want is to deserve what you want.",
    "A majority of life’s errors are caused by forgetting what one is really trying to do.",
    "What we learn from history is that people don’t learn from history.",
    "Develop into a lifelong self-learner through voracious reading; cultivate curiosity and strive to become a little wiser every day.",
    "Acknowledging what you don’t know is the dawning of wisdom.",
    "We try more to profit from always remembering the obvious than from grasping the esoteric.",
    "Mimicking the herd invites regression to the mean.",
    "The big money is not in the buying and the selling, but in the waiting.",
    "In the world of business, the people who are most successful are those who are doing what they love.",
    "The highest rates of return I've ever achieved were the result of investing in very high quality businesses that I knew were going to do well over time.",
    "When you borrow a man’s car, you always return it with a full tank of gas.",
    "I think a long attention span and the ability to concentrate and work on one thing for a long time is a huge advantage.",
    "We both (Warren Buffett and himself) insist on a lot of time being available almost every day to just sit and think.",
    "People calculate too much and think too little.",
    "A great business at a fair price is superior to a fair business at a great price.",
    "To get what you want, you have to deserve what you want. The world is not yet a crazy enough place to reward a whole bunch of undeserving people.",
    "It's not supposed to be easy. Anyone who finds it easy is stupid.",
    "The highest form a civilization can reach is a seamless web of deserved trust.",
    "The best thing a human being can do is to help another human being know more.",
    "It’s waiting that helps you as an investor, and a lot of people just can’t stand to wait.",
    "There are no 20-year-olds that are not confused.",
    "I always say I want to know where I would die so I can never go there.",
    "We get these questions a lot. The answer is that we don’t have a lot of answers.",
    "If you’re not a little confused by what’s going on, you don’t understand it.",
    "If you rise in life, you have to behave in a certain way.",
    "There’s no learning from the second kick of a mule.",
    "If you have a 150 IQ, sell 30 points to somebody else. You need to be smart enough to get into the game and then smart enough to realize you’ve got to get into it with a lot of people who know more than you do.",
    "We have a passion for keeping things simple.",
    "We look for a horse with one chance in two of winning and which pays you three to one.",
    "It’s better to be a one-legged man in an ass-kicking contest than a one-eyed man in a kingdom of the blind.",
    "Knowing what you don’t know is more useful than being brilliant.",
    "In my whole life, I have known no wise people (over a broad subject matter area) who didn't read all the time -- none, zero. You'd be amazed at how much Warren reads--and at how much I read. My children laugh at me. They think I'm a book with a couple of legs sticking out.",
    "Spend each day trying to be a little wiser than you were when you woke up. Day by day, and at the end of the day-if you live long enough-like most people, you will get out of life what you deserve.",
    "We both (Charlie Munger and Warren Buffett) insist on a lot of time being available almost every day to just sit and think. That is very uncommon in American business. We read and think.",
    "How to find a good spouse? -the best single way is to deserve a good spouse.",
    "There is no better teacher than history in determining the future... There are answers worth billions of dollars in 30$ history book.",
    "Acquire worldly wisdom and adjust your behavior accordingly. If your new behavior gives you a little temporary unpopularity with your peer group…then to hell with them.",
    "It takes character to sit with all that cash and to do nothing. I didn't get top where I am by going after mediocre opportunities.",
    "We all are learning, modifying, or destroying ideas all the time. Rapid destruction of your ideas when the time is right is one of the most valuable qualities you can acquire. You must force yourself to consider arguments on the other side.",
    "What are the secret of success? -one word answer : 'rational'",
    "I constantly see people rise in life who are not the smartest, sometimes not even the most diligent, but they are learning machines. They go to bed every night a little wiser than they were when they got up and boy does that help, particularly when you have a long run ahead of you.",
    "I believe in the discipline of mastering the best that other people have ever figured out. I don’t believe in just sitting down and trying to dream it all up yourself. Nobody’s that smart.",
    "Remember that reputation and integrity are your most valuable assets—and can be lost in a heartbeat.",
    "Go to bed smarter than when you woke up.",
    "Envy is a really stupid sin because it’s the only one you could never possibly have any fun at. There’s a lot of pain and no fun. Why would you want to get on that trolley?",
    "I never allow myself to hold an opinion on anything that I don't know the other side's argument better than they do.",
    "The best armour of old age is a well spent life perfecting it.",
    "The iron rule of nature is: you get what you reward for. If you want ants to come, you put sugar on the floor.",
    "If something is too hard, we move on to something else. What could be simpler than that?",
    "All I want to know is where I'm going to die so I'll never go there.",
    "If you don’t get this elementary, but mildly unnatural, mathematics of elementary probability into your repertoire, then you go through a long life like a one-legged man in an ass-kicking contest.",
    "In my whole life, I have known no wise people (over a board subject matter area) who didn't read all the time - none, zero. You'd be amazed how much Warren reads - and at how much I read. My children laugh at me. They think I'm a book with a couple of legs sticking out.",
    "Mimicking the herd invites regression to the mean (merely average performance).",
    "It's the work on your desk. Do well with what you already have and more will come in.",
    "Man’s imperfect, limited-capacity brain easily drifts into working with what’s easily available to it. And the brain can’t use what it can’t remember or when it’s blocked from recognizing because it’s heavily influenced by one or more psychological tendencies bearing strongly on it … the deep structure of the human mind requires that the way to full scope competency of virtually any kind is to learn it all to fluency—like it or not.",
    "The great algorithm to remember in dealing with this tendency is simple: an idea or a fact is not worth more merely because it’s easily available to you.",
    "Our job is to find a few intelligent things to do, not to keep up with every damn thing in the world.",
    "We have three baskets: in, out, and too tough. … We have to have a special insight, or we’ll put it in the 'too tough' basket.",
    "An example of a really responsible system is the system the Romans used when they built an arch. The guy who created the arch stood under it as the scaffolding was removed. It’s like packing your own parachute.",
    "Warren talks about these discounted cash flows. I’ve never seen him do one.",
    "Crowd folly, the tendency of humans, under some circumstances, to resemble lemmings, explains much foolish thinking of brilliant men and much foolish behavior.",
    "Pain-Avoiding Psychological Denial One should recognize reality even when one doesn’t like it.",
    "The reason we avoid the word 'synergy' is because people generally claim more synergistic benefits than will come. Yes, it exists, but there are so many false promises. Berkshire is full of synergies - we don't avoid synergies, just claims of synergies.",
    "It’s not possible for investors to consistently outperform the market. Therefore you’re best served investing in a diversified portfolio of low-cost index funds [or exchange-traded funds].",
    "Forgetting your mistakes is a terrible error if you’re trying to improve your cognition. Reality doesn’t remind you. Why not celebrate stupidities in both categories?",
    "It's not the bad ideas that do you in, it’s the good ideas. And you may say, 'That can't be so. That's paradoxical. What he [Graham] meant was that if a thing is a bad idea, it’s hard to overdo. But where there is a good idea with a core of essential and important truth, you can’t ignore it. And then it's so easy to overdo it. So the good ideas are a wonderful way to suffer terribly if you overdo them.",
    "It is remarkable how much long-term advantage we have gotten by trying to be consistently not stupid, instead of trying to be very intelligent.",
    "Show me the incentive and I'll show you the outcome.",
    "All the equity investors, in total, will surely bear a performance disadvantage per annum equal to the total croupiers’ costs they have jointly elected to bear. This is an inescapable fact of life. And it is also inescapable that exactly half of the investors will get a result below the median result after the croupiers’ take, which median result may well be somewhere between unexciting and lousy.",
    "Stay within a well-defined circle of competence.",
    "Avoid unnecessary transactional taxes and frictional costs; never take action for its own sake.",
    "Take a simple idea and take it seriously.",
    "Resist the craving for false precision, false certainties, etc.",
    "Good ideas are rare—when the odds are greatly in your favor, bet (allocate) heavily.",
    "You don't have to pee on an electric fence to learn not to do it.",
    "I want to think about things where I have an advantage over others. I don't want to play a game where people have an advantage over me. I don't play in a game where other people are wise and I am stupid. I look for a game where I am wise, and they are stupid. And believe me, it works better. God bless our stupid competitors. They make us rich.",
    "Own your work and compound credibility.",
    "Remember that just because other people agree or disagree with you doesn’t make you right or wrong—the only thing that matters is the correctness of your analysis and judgment.",
    "Whenever you think that some situation or some person is ruining your life, it’s actually you who are ruining your life. It’s such a simple idea. Feeling like a victim is a perfectly disastrous way to make go through life. If you just take the attitude that however bad it is in anyway, it’s always your fault and you just fix it as best you can – the so-called 'iron prescription.'",
    "It's amazing how intelligent it is just to spend some time sitting. A lot of people are way too active.",
    "The principal job of an academic administration is to keep the people who don’t matter from interfering with the work of the people who do.",
    "And the models have to come from multiple disciplines—because all the wisdom of the world is not to be found in one little academic department. That’s why poetry professors, by and large, are so unwise in a worldly sense. They don’t have enough models in their heads. So you’ve got to have models across a fair array of disciplines.",
    "Three rules for a career: 1) Don't sell anything you wouldn't buy yourself. 2) Don't work for anyone you don't respect and admire. 3) Work only with people you enjoy.",
    "Always take the high road, it's far less crowded.",
    "Not drifting into extreme ideology is very, very important in life. If you want to end up wise, heavy ideology is very likely to prevent that outcome.",
    "It’s not given to human beings to have such talent that they can just know everything about everything all the time. But it is given to human beings who work hard at it—who look and sift the world for a mispriced bet—that they can occasionally find one. And the wise ones bet heavily when the world offers them that opportunity. They bet big when they have the odds. And the rest of the time, they don’t. It’s just that simple.",
    "Wesco continues to try more to profit from always remembering the obvious than from grasping the esoteric. … It is remarkable how much long-term advantage people like us have gotten by trying to be consistently not stupid, instead of trying to be very intelligent. There must be some wisdom in the folk saying, `It’s the strong swimmers who drown.'",
    "The ability to destroy your ideas rapidly instead of slowly when the occasion is right is one of the most valuable things. You have to work hard on it. Ask yourself what are the arguments on the other side. It’s bad to have an opinion you’re proud of if you can’t state the arguments for the other side better than your opponents. This is a great mental discipline.",
    "Charlie counts preparation, patience, discipline, and objectivity among his most fundamental guiding principles. He will not deviate from these principles, regardless of group dynamics, emotional itches, or popular wisdom that 'this time around it’s different.'",
    "To avoid what he calls physics envy, the common human craving to reduce enormously complex systems (such as those in economics) to one-size-fits-all Newtonian formulas. Instead, he faithfully honors Albert Einstein’s admonition: 'A scientific theory should be as simple as possible, but no simpler.'",
    "Warren always said it’s probably always a mistake to marry for money and it’s really stupid if you’re already rich.",
    "Tendencies, two of which are exceptionally prone to cause trouble: 1) incentive-caused bias, a natural cognitive drift toward the conclusion that what is good for the professional is good for the client and the wider civilization; and 2) man-with-a-hammer tendency, with the name taken from the proverb 'To a man with only a hammer, every problem tends to look pretty much like a nail.'",
    "I find it quite useful to think of a (partly) free market economy as sort of the equivalent of an ecosystem. Just as animals flourish in niches, people who specialize in some narrow niche can do very well.",
    "The school of life is always open, if you aren't learning more, you are falling further behind.",
    "My second prescription for misery is to learn everything you possibly can from your own experience, minimizing what you learn vicariously from the good and bad experiences of others, living and dead. This prescription is a sure-shot producer of misery and second-rate achievement.",
    "Never allow oneself to have an opinion on anything when you don't know the other side's arguments better than they do.",
    "He also had a very interesting competitive strategy in the early days. He was like a prizefighter who wanted a great record so he could be in the finals and make a big TV hit. So what did he do? He went out and fought 42 palookas. Right? And the result was knockout, knockout, knockout—42 times.",
    "In my life, I avoid things that are stupid, evil, or make me look bad in comparison with someone else.",
    "Well, luckily I had the idea at a very early age that the safest way to try to get what you want is to try to deserve what you want.",
    "If you buy something because it’s undervalued, then you have to think about selling it when it approaches your calculation of its intrinsic value. That’s hard.",
    "Look first for someone both smarter and wiser than you are. After locating him (or her), ask him not to flaunt his superiority so that you may enjoy acclaim for the many accomplishments that sprang from his thoughts and advice. Seek a partner who will never second-guess you nor sulk when you make expensive mistakes. Look also for a generous soul who will put up his own money and work for peanuts. Finally, join with someone who will constantly add to the fun as you travel a long road together.",
    "Life and its various passages can be hard, brutally hard. The three things I have found helpful in coping with its challenges are: Have low expectations. Have a sense of humor. Surround yourself with the love of friends and family. Above all, live with change and adapt to it. If the world didn’t change, I’d still have a 12 handicap.",
    "No man’s life should be accounted a happy one until it is over.",
    "Yeah, I’m passionate about wisdom. I’m passionate about accuracy and some kinds of curiosity. Perhaps I have some streak of generosity in my nature and a desire to serve values that transcend my brief life. But maybe I’m just here to show off. Who knows? I believe in the discipline of mastering the best that other people have ever figured out. I don’t believe in just sitting down and trying to dream it all up yourself. Nobody’s that smart.",
    "More important than the will to win is the will to prepare.",
    "If you skillfully follow the multidisciplinary path, you will never wish to come back. It would be like cutting off your hands.",
    "The idea that life is a series of adversities and each one is an opportunity to behave well instead of badly is a very, very good idea.",
    "The ability to destroy your ideas rapidly instead of slowly when the occasion is right is one of the most valuable things. You have to work hard on it. Ask yourself what are the arguments on the other side. It’s bad to have an opinion you’re proud of if you can’t state the arguments for the other side better than your opponents. This is a great mental discipline.",
    "I find it quite useful to think of a (partly) free market economy as sort of the equivalent of an ecosystem. Just as animals flourish in niches, people who specialize in some narrow niche can do very well.",
    "In my life, I avoid things that are stupid, evil, or make me look bad in comparison with someone else.",
    "However, both Warren and I are very good at changing our prior conclusions. We work at developing that facility because without it, disaster often comes.",
    "If, in your thinking, you rely entirely on others—often through purchase of professional advice—whenever outside a small territory of your own, you will suffer much calamity.",
    "The best antidote to folly from an excess of self-regard is to force yourself to be more objective when you are thinking about yourself, your family and friends, your property, and the value of your past and future activity. This isn't easy to do well and won't work perfectly but it will work much better than simply letting psychological nature take its normal course.",
    "The best armour of old age is a well spent life preceding it.",
    "It's the work on your desk. Do well with what you already have and more will come in.",
    "Man’s imperfect, limited-capacity brain easily drifts into working with what’s easily available to it. And the brain can’t use what it can’t remember or when it’s blocked from recognizing because it’s heavily influenced by one or more psychological tendencies bearing strongly on it … the deep structure of the human mind requires that the way to full scope competency of virtually any kind is to learn it all to fluency—like it or not.",
    "The great algorithm to remember in dealing with this tendency is simple: an idea or a fact is not worth more merely because it’s easily available to you.",
    "Our job is to find a few intelligent things to do, not to keep up with every damn thing in the world.",
    "We have three baskets: in, out, and too tough. … We have to have a special insight, or we’ll put it in the 'too tough' basket.",
    "An example of a really responsible system is the system the Romans used when they built an arch. The guy who created the arch stood under it as the scaffolding was removed. It’s like packing your own parachute.",
    "Warren talks about these discounted cash flows. I’ve never seen him do one.",
    "Crowd folly, the tendency of humans, under some circumstances, to resemble lemmings, explains much foolish thinking of brilliant men and much foolish behavior.",
    "Pain-Avoiding Psychological Denial One should recognize reality even when one doesn’t like it.",
    "The reason we avoid the word 'synergy' is because people generally claim more synergistic benefits than will come. Yes, it exists, but there are so many false promises. Berkshire is full of synergies - we don't avoid synergies, just claims of synergies.",
    "It’s not possible for investors to consistently outperform the market. Therefore you’re best served investing in a diversified portfolio of low-cost index funds [or exchange-traded funds].",
    "Forgetting your mistakes is a terrible error if you’re trying to improve your cognition. Reality doesn’t remind you. Why not celebrate stupidities in both categories?",
    "It's not the bad ideas that do you in, it’s the good ideas. And you may say, 'That can't be so. That's paradoxical. What he [Graham] meant was that if a thing is a bad idea, it’s hard to overdo. But where there is a good idea with a core of essential and important truth, you can’t ignore it. And then it's so easy to overdo it. So the good ideas are a wonderful way to suffer terribly if you overdo them.",
    "It is remarkable how much long-term advantage we have gotten by trying to be consistently not stupid, instead of trying to be very intelligent.",
    "Show me the incentive and I'll show you the outcome.",
    "All the equity investors, in total, will surely bear a performance disadvantage per annum equal to the total croupiers’ costs they have jointly elected to bear. This is an inescapable fact of life. And it is also inescapable that exactly half of the investors will get a result below the median result after the croupiers’ take, which median result may well be somewhere between unexciting and lousy.",
    "Stay within a well-defined circle of competence.",
    "Avoid unnecessary transactional taxes and frictional costs; never take action for its own sake.",
    "Take a simple idea and take it seriously.",
    "Resist the craving for false precision, false certainties, etc.",
    "Good ideas are rare—when the odds are greatly in your favor, bet (allocate) heavily.",
    "You don't have to pee on an electric fence to learn not to do it.",
    "I want to think about things where I have an advantage over others. I don't want to play a game where people have an advantage over me. I don't play in a game where other people are wise and I am stupid. I look for a game where I am wise, and they are stupid. And believe me, it works better. God bless our stupid competitors. They make us rich.",
    "Own your work and compound credibility.",
    "Remember that just because other people agree or disagree with you doesn’t make you right or wrong—the only thing that matters is the correctness of your analysis and judgment.",
    "Whenever you think that some situation or some person is ruining your life, it’s actually you who are ruining your life. It’s such a simple idea. Feeling like a victim is a perfectly disastrous way to make go through life. If you just take the attitude that however bad it is in anyway, it’s always your fault and you just fix it as best you can – the so-called 'iron prescription.'",
    "It's amazing how intelligent it is just to spend some time sitting. A lot of people are way too active.",
    "The principal job of an academic administration is to keep the people who don’t matter from interfering with the work of the people who do.",
    "And the models have to come from multiple disciplines—because all the wisdom of the world is not to be found in one little academic department. That’s why poetry professors, by and large, are so unwise in a worldly sense. They don’t have enough models in their heads. So you’ve got to have models across a fair array of disciplines.",
    "Three rules for a career: 1) Don't sell anything you wouldn't buy yourself. 2) Don't work for anyone you don't respect and admire. 3) Work only with people you enjoy.",
    "Always take the high road, it's far less crowded.",
    "Not drifting into extreme ideology is very, very important in life. If you want to end up wise, heavy ideology is very likely to prevent that outcome.",
    "It’s not given to human beings to have such talent that they can just know everything about everything all the time. But it is given to human beings who work hard at it—who look and sift the world for a mispriced bet—that they can occasionally find one. And the wise ones bet heavily when the world offers them that opportunity. They bet big when they have the odds. And the rest of the time, they don’t. It’s just that simple.",
    "Wesco continues to try more to profit from always remembering the obvious than from grasping the esoteric. … It is remarkable how much long-term advantage people like us have gotten by trying to be consistently not stupid, instead of trying to be very intelligent. There must be some wisdom in the folk saying, `It’s the strong swimmers who drown.'",
    "The ability to destroy your ideas rapidly instead of slowly when the occasion is right is one of the most valuable things. You have to work hard on it. Ask yourself what are the arguments on the other side. It’s bad to have an opinion you’re proud of if you can’t state the arguments for the other side better than your opponents. This is a great mental discipline.",
    "Charlie counts preparation, patience, discipline, and objectivity among his most fundamental guiding principles. He will not deviate from these principles, regardless of group dynamics, emotional itches, or popular wisdom that 'this time around it’s different.'",
    "To avoid what he calls physics envy, the common human craving to reduce enormously complex systems (such as those in economics) to one-size-fits-all Newtonian formulas. Instead, he faithfully honors Albert Einstein’s admonition: 'A scientific theory should be as simple as possible, but no simpler.'",
    "Warren always said it’s probably always a mistake to marry for money and it’s really stupid if you’re already rich.",
    "Tendencies, two of which are exceptionally prone to cause trouble: 1) incentive-caused bias, a natural cognitive drift toward the conclusion that what is good for the professional is good for the client and the wider civilization; and 2) man-with-a-hammer tendency, with the name taken from the proverb 'To a man with only a hammer, every problem tends to look pretty much like a nail.'",
    "I find it quite useful to think of a (partly) free market economy as sort of the equivalent of an ecosystem. Just as animals flourish in niches, people who specialize in some narrow niche can do very well.",
    "The school of life is always open, if you aren't learning more, you are falling further behind.",
    "My second prescription for misery is to learn everything you possibly can from your own experience, minimizing what you learn vicariously from the good and bad experiences of others, living and dead. This prescription is a sure-shot producer of misery and second-rate achievement.",
    "Never allow oneself to have an opinion on anything when you don't know the other side's arguments better than they do.",
    "He also had a very interesting competitive strategy in the early days. He was like a prizefighter who wanted a great record so he could be in the finals and make a big TV hit. So what did he do? He went out and fought 42 palookas. Right? And the result was knockout, knockout, knockout—42 times.",
    "In my life, I avoid things that are stupid, evil, or make me look bad in comparison with someone else.",
    "Well, luckily I had the idea at a very early age that the safest way to try to get what you want is to try to deserve what you want.",
    "If you buy something because it’s undervalued, then you have to think about selling it when it approaches your calculation of its intrinsic value. That’s hard.",
    "Look first for someone both smarter and wiser than you are. After locating him (or her), ask him not to flaunt his superiority so that you may enjoy acclaim for the many accomplishments that sprang from his thoughts and advice. Seek a partner who will never second-guess you nor sulk when you make expensive mistakes. Look also for a generous soul who will put up his own money and work for peanuts. Finally, join with someone who will constantly add to the fun as you travel a long road together.",
    "Life and its various passages can be hard, brutally hard. The three things I have found helpful in coping with its challenges are: Have low expectations. Have a sense of humor. Surround yourself with the love of friends and family. Above all, live with change and adapt to it. If the world didn’t change, I’d still have a 12 handicap.",
    "No man’s life should be accounted a happy one until it is over.",
    "Yeah, I’m passionate about wisdom. I’m passionate about accuracy and some kinds of curiosity. Perhaps I have some streak of generosity in my nature and a desire to serve values that transcend my brief life. But maybe I’m just here to show off. Who knows? I believe in the discipline of mastering the best that other people have ever figured out. I don’t believe in just sitting down and trying to dream it all up yourself. Nobody’s that smart.",
    "More important than the will to win is the will to prepare.",
    "If you skillfully follow the multidisciplinary path, you will never wish to come back. It would be like cutting off your hands.",
    "The idea that life is a series of adversities and each one is an opportunity to behave well instead of badly is a very, very good idea.",
    "The ability to destroy your ideas rapidly instead of slowly when the occasion is right is one of the most valuable things. You have to work hard on it. Ask yourself what are the arguments on the other side. It’s bad to have an opinion you’re proud of if you can’t state the arguments for the other side better than your opponents. This is a great mental discipline.",
    "I find it quite useful to think of a (partly) free market economy as sort of the equivalent of an ecosystem. Just as animals flourish in niches, people who specialize in some narrow niche can do very well.",
    "In my life, I avoid things that are stupid, evil, or make me look bad in comparison with someone else.",
    "However, both Warren and I are very good at changing our prior conclusions. We work at developing that facility because without it, disaster often comes.",
    "If, in your thinking, you rely entirely on others—often through purchase of professional advice—whenever outside a small territory of your own, you will suffer much calamity.",
    "The best antidote to folly from an excess of self-regard is to force yourself to be more objective when you are thinking about yourself, your family and friends, your property, and the value of your past and future activity. This isn't easy to do well and won't work perfectly but it will work much better than simply letting psychological nature take its normal course.",
    "Never allow oneself to have an opinion on anything when you don't know the other side's arguments better than they do.",
    "Look first for someone both smarter and wiser than you are. After locating him (or her), ask him not to flaunt his superiority so that you may enjoy acclaim for the many accomplishments that sprang from his thoughts and advice. Seek a partner who will never second-guess you nor sulk when you make expensive mistakes. Look also for a generous soul who will put up his own money and work for peanuts. Finally, join with someone who will constantly add to the fun as you travel a long road together.",
    "I think a life properly lived is just learn, learn, learn all the time.",
    "Opportunity doesn’t come often, so seize it when it does. Opportunity meeting the prepared mind—that’s the game.",
    "No man’s life should be accounted a happy one until it is over.",
    "Well, the first rule is that you can’t really know anything if you just remember isolated facts and try to bang ‘em back. If the facts don’t hang together on a latticework of theory, you don’t have them in a usable form. You’ve got to have mental models in your head. And you’ve got to array your experience, both vicarious and direct, on this latticework of models.",
    "First (prescription for misery), be unreliable. Do not faithfully do what you have engaged to do. If you will only master this one habit, you will more that counterbalance the combined effect of all your virtues, howsoever great. If you like being distrusted and excluded from the best human contribution and company, this prescription is for you. Master this one habit, and you will always play the role of the hare in the fable, except that instead of being outrun by the one fine turtle, you will be outrun by hordes and hordes of mediocre turtles and even some mediocre turtles on crutches.",
    "It is remarkable how much long-term advantage people like us have gotten by trying to be not stupid instead of trying to be very intelligent.",
    "What I found, in my extended attempts to complete by informal means my stunted education, was that, plugging along with only ordinary will but with the fundamental organizing ethos as my guide, my ability to serve everything I loved was enhanced far beyond my deserts. Large gains came in places that seemed unlikely as I started out, sometimes making me like the only one without a blindfold in a high-stakes game of pin the tail on the donkey. For instance, I was productively led into psychology, where I had no plans to go, creating large advantages that deserve a story on another day.",
    "As he says, “We have three baskets for investing: yes, no, and too tough to understand.",
    "So there’s an iron rule that just as you want to start getting worldly wisdom by asking “Why, why, why?,” in communicating with other people about everything, you want to include why, why, why. Even if it’s obvious, it’s wise to stick in the why.",
    "In contrast, most people early achieve and later intensify a tendency to process new and disconfirming information so that any original conclusion remains intact.",
    "The fourth helpful notion is that the best and most practical wisdom is elementary academic wisdom. But there is one extremely important qualification: You must think in a multidisciplinary manner.",
    "However, averaged out, betting on the quality of a business is better than betting on the quality of management. In other words, if you have to choose one, bet on the business momentum, not the brilliance of the manager. But very rarely, you find a manager who’s so good that you’re wise to follow him into what looks like a mediocre business.",
    "Or, in his own words: “What I’m against is being very confident and feeling that you know, for sure, that your particular action will do more good than harm. You’re dealing with highly complex systems wherein everything is interacting with everything else.",
    "There is nothing more counterproductive than envy. Someone in the world will always be better than you. Of all the sins, envy is easily the worst, because you can't even haven any fun with it. It's a total net loss.",
    "All I want to know is where I'm going to die, so I'll never go there.",
    "A few major opportunities, clearly recognizable as such, will usually come to one who continuously searches and waits, with a curious mind that loves diagnosis involving multiple variables. And then all that is required is a willingness to bet heavily when the odds are extremely favorable, using resources available as a result of prudence and patience in the past.",
    "If anything, I now believe even more strongly that 1) reliability is essential for progress in life and 2) while quantum mechanics is unlearnable for a vast majority, reliability can be learned to great advantage by almost anyone.",
    "There’s another type of person I compare to an example from biology: When a bee finds nectar, it comes back and does a little dance that tells the rest of the hive, as a matter of genetic programming, which direction to go and how far. So about 40 or 50 years ago, some clever scientist stuck the nectar straight up. Well, the nectar’s never straight up in the ordinary life of a bee. The nectar’s out. So the bee finds the nectar and returns to the hive. But it doesn’t have the genetic programming to do a dance that says straight up. So what does it do? Well, if it were like Jack Welch, it would just sit there. But what it actually does is to dance this incoherent dance that gums things up. A lot of people are like that bee. They attempt to answer a question like that. And that is a huge mistake. Nobody expects you to know everything about everything. I try to get rid of people who always confidently answer questions about which they don’t have any real knowledge. To me, they’re like the bee dancing its incoherent dance. They’re just screwing up the hive.",
    "This sort of tragedy is caused by letting the slop run. You must stop slop early. It’s very hard to stop slop and moral failure if you let it run for a while.",
    "Fourth, in filling scarce academic vacancies, professors of superstrong, passionate political ideology, whether on the left or right, should usually be avoided. So also for students. Best-form multidisciplinarity requires an objectivity such passionate people have lost, and a difficult synthesis is not likely to be achieved by minds in ideological fetters. In our day, some Harvard Law professors could and did point to a wonderful example of just such ideology-based folly. This, of course, was the law school at Yale, which was then viewed by many at Harvard as trying to improve legal education by importing a particular political ideology as a dominant factor.",
    "So in the game of life, get the needed models into your head and think it through forward and backward. What works in bridge will work in life.",
    "Richard Feynman as follows: 'The first principle is that you must not fool yourself, and you’re the easiest person to fool.'",
    "If you rise in life, you have to behave a certain way. You can go to a strip club if you're a beer-swilling sand shoveler, but if you're the bishop of Boston, you shouldn't go.",
    "It is in the nature of things, as Jacobi knew, that many hard problems are best solved only when they are addressed backward. For instance, when almost everyone else was trying to revise the electromagnetic laws of [James Clerk] Maxwell to be consistent with the motion laws of Newton, Einstein discovered special relativity as he made a 180-degree turn and revised Newton’s laws to fit Maxwell’s.",
    "Without numerical fluency … you are like a one-legged man in an ass-kicking contest.",
    "And once we get into microeconomics, we get into the concept of advantages of scale.",
    "So you have to figure out what your own aptitudes are. If you play games where other people have the aptitudes and you don’t, you’re going to lose. And that’s as close to certain as any prediction you can make. You have to figure out where you’ve got an edge. And you’ve got to play within your own circle of competence.",
    "First, there’s mathematics. Obviously, you’ve got to be able to handle numbers and quantities—basic arithmetic. And the great useful model, after compound interest, is the elementary math of permutations and combinations. That was taught in my day in the sophomore year in high school. I suppose by now, in great private schools, it’s probably down to the eighth grade or",
    "Therefore, if you interpret being true to yourself as requiring that you retain every notion of your youth, you will be safely underway, not only toward maximizing ignorance but also toward whatever misery can be obtained through unpleasant experiences in business.",
    "Reality evoked by the Shavian character who said, 'In the last analysis, every profession is a conspiracy against the laity.'",
    "If you have competence, you pretty much know its boundaries already. To ask the question [of whether you are past the boundary] is to answer it.",
    "In my whole life, I have known no wise people who didn’t read all the time – none, zero.",
    "Yet, within this pursuit of rationality and simplicity, he is careful to avoid what he calls physics envy, the common human craving to reduce enormously complex systems (such as those in economics) to one-size-fits-all Newtonian formulas. Instead, he faithfully honors Albert Einstein’s admonition: 'A scientific theory should be as simple as possible, but no simpler.'",
    "The second idea that I developed very early is that there’s no love that’s so right as admiration-based love, and such love should include the instructive dead.",
    "Some of the worst dysfunctions in businesses come from the fact that they balkanize reality into little individual departments, with territoriality and turf protection and so forth. So if you want to be a good thinker, you must develop a mind that can jump the jurisdictional boundaries. You don’t have to know it all. Just take in the best big ideas from all these disciplines. And it’s not that hard to do.",
    "Spend each day trying to be a little wiser than you were when you woke up. Discharge your duties faithfully and well. Step by step you get ahead, but not necessarily in fast spurts. But you build discipline by preparing for fast spurts.... Slug it out one inch at a time, day by day. At the end of the day–if you live long enough–most people get what they deserve.",
    "Eventually, his own work attracted wide attention, and he said of his work, 'If I have seen a little farther than other men, it is because I stood on the shoulders of giants.' The bones of that man lie buried now, in Westminster Abbey, under an unusual inscription: 'Here lie the remains of all that was mortal in Sir Isaac Newton.'",
    "It takes character to sit there with all that cash and do nothing. I didn't get to where I am by going after mediocre opportunities.",
    "Two partners that I chose for one little phase of my life had the following rule: They created a little design/build construction team, and they sat down and said, two-man partnership, divide everything equally, here’s the rule; 'Whenever we’re behind in our commitments to other people, we will both work 14 hours a day until we’re caught up.' Well, needless to say, that firm didn’t fail. The people died rich. It’s such a simple idea.",
    "I think track records are very important. If you start early trying to have a perfect one in some simple thing like honesty, you're well on your way to success in the world.",
    "Demosthenes noted, 'What a man wishes, he will believe.'",
    "Why fret too much about what you can't fix? Just keep your head down & do your best.",
    "It is not always recognized that to function best, morality should sometimes appear unfair, like most worldly outcomes. The craving for perfect fairness causes a lot of terrible problems in system function. Some systems should be made deliberately unfair to individuals because they'll be fairer on average for all of us. Thus, there can be virtue in apparent non-fairness.",
    "Which helps the objective person end up like the only player without a blindfold in a game of pin the tail on the donkey.",
    "Lifelong learning, intellectual curiosity, sobriety, avoidance of envy and resentment, reliability, learning from the mistakes of others, perseverance, objectivity, willingness to test one’s own beliefs, and many more. But his advice comes not in the form of stentorian admonishments; instead, Charlie uses humor, inversions (following the directive of the great algebraist [Carl] Jacobi to 'invert, always invert'), and paradox to provide sage counsel about life’s toughest challenges. Charlie also employs historical and business case studies to great effect. In these presentations, he makes his points with subtlety and texture, often using a story-like context instead of abstract statements of theory. He regales his audience with humorous anecdotes and poignant tales rather than with a blizzard of facts and figures.",
    "Which models are the most reliable? Well, obviously, the models that come from hard science and engineering are the most reliable models on this earth. And engineering quality control—at least the guts of it that matters to you and me and people who are not professional engineers—is very much based on the elementary mathematics of Fermat and Pascal: It costs so much, and you get so much less likelihood of it breaking if you spend this much. It’s all elementary high school mathematics.",
    "The first helpful notion is that it is usually best to simplify problems by deciding big no-brainer questions first. The second helpful notion mimics Galileo’s conclusion that scientific reality is often revealed only by math as if math was the language of God.",
    "Face your big troubles, don’t sweep them under the rug.",
    "The first rule is that you can't really know anything if you just remember isolated facts and try and bang 'em back. If the facts don't hang together on a latticework of theory, you don't have them in a usable form.",
    "Limited partnerships that do some combination of the following: unleveraged investment in high-tech corporations in their infancy; leveraged investments in corporate buyouts; leveraged relative value trades in equities; and leveraged convergence trades and other exotic trades in all kinds of securities and derivatives.",
    "And deprival-superreaction syndrome also comes in: You’re going to lose the whole thing if you don’t put in a little more. People go broke that way because they can’t stop, rethink, and say, 'I can afford to write this one off and live to fight again. I don’t have to pursue this thing as an obsession, in a way that will break me.'",
    "For example, buy Walmart when Sam Walton first goes public and so forth. A lot of people try to do just that. And it’s a very beguiling idea. If I were a young man, I might actually go into it. But it doesn’t work for Berkshire Hathaway anymore because we’ve got too much money. We can’t find anything that fits our size parameter that way. Besides, we’re set in our ways. But I regard finding them small as a perfectly intelligent approach for somebody to try with discipline. It’s just not something that I’ve done.",
    "The first helpful notion is that it is usually best to simplify problems by deciding big no-brainer questions first.",
    "And he will also suffer from the psychological defect caught by the proverb 'To a man with a hammer, every problem looks like a nail.' The fifth helpful notion is that really big effects, lollapalooza effects, will often come only from large combinations of factors.",
    "He was a duck sitting on a pond, and they raised the level of the pond.",
    "Instead, you must do your multidisciplinary thinking in accord with Ben Franklin’s prescription in Poor Richard: 'If you want it done, go. If not, send.'",
    "If you minimize objectivity, you ignore not only a lesson from Darwin but also one from Einstein. Einstein said that his successful theories came from 'curiosity, concentration, perseverance, and self-criticism.' And by self-criticism, he meant the testing and destruction of his own well-loved ideas.",
    "Then, while people are justifying all these layers, it takes forever to get anything done. They’re too slow to make decisions, and nimbler people run circles around them.",
    "Includes such things as the current and prospective regulatory climate; the state of labor, supplier, and customer relations; the potential impact of changes in technology; competitive strengths and vulnerabilities; pricing power; scalability; environmental issues; and, notably, the presence of hidden exposures.",
    "Darwin’s result was due in large measure to his working method, which violated all my rules for misery and particularly emphasized a backward twist in that he always gave priority attention to evidence tending to disconfirm whatever cherished and hard-won theory he already had.",
    "The big money is not in the buying and selling ... but in the waiting.",
    "The second helpful notion mimics Galileo’s conclusion that scientific reality is often revealed only by math as if math was the language of God. Galileo’s attitude also works well in messy, practical life. Without numerical fluency, in the part of life most of us inhabit, you are like a one-legged man in an ass-kicking contest.",
    "Roger Fisher.",
    "The fourth helpful notion is that the best and most practical wisdom is elementary academic wisdom. But there is one extremely important qualification: You must think in a multidisciplinary manner. You must routinely use all the easy-to-learn concepts from the freshman course in every basic subject.",
    "Net. So it’s much better to let some things go uncompensated—to let life be hard—than to create systems that are easy to cheat.",
    "A great many people conclude that something can't be evil if they are profiting from it.",
    "You need to have a passionate interest in why things are happening. That cast of mind, kept over long periods, gradually improves your ability to focus on reality. If you don't have the cast of mind, you're destined for failure even if you have a high I.Q.",
    "As Buffett and I say over and over again, we don’t leap 7-foot fences. Instead, we look for 1-foot fences with big rewards on the other side. So we’ve succeeded by making the world easy for ourselves, not by solving hard problems.",
    "First, be unreliable. Do not faithfully do what you have engaged to do. If you will only master this one habit, you will more than counterbalance the combined effect of all your virtues, howsoever great.",
    "I think the tragedy in life is to be so timid you don't play hard enough.",
    "It's an effective sales technique in America to put a foolish projection on a desk. And if you're an investment banker, it's an art form.",
    "To (Cicero), pride in a job well done is vastly constructive. For instance, it motivates good conduct in early life because, in remembrance, you can make yourself happier when old. To which, aided by modern knowledge, I would add 'and, besides, as you pat yourself on the back for behaving well, you will improve your future conduct.'",
    "So you can get very remarkable investment results if you think more like a winning pari mutuel player. Just think of it as a heavy-odds against game full of bullshit and craziness with an occasional mispriced something or other. And you’re probably not going to be smart enough to find thousands in a lifetime. And when you get a few, you really load up. It’s just that simple.",
    "It is not usually the conscious malfeasance of your narrow professional adviser that does you in. Instead, your troubles come from his subconscious bias. His cognition will often be impaired, for your purposes, by financial incentives different from yours. And he will also suffer from the psychological defect caught by the proverb 'To a man with a hammer, every problem looks like a nail.'",
    "Acquire worldly wisdom and adjust your behavior accordingly. If your new behavior gives you a little temporary unpopularity with your peer group... then to hell with them.",
    "We all know of individuals, modern Ben Franklins, who have 1) achieved a massive multidisciplinary synthesis with less time in formal education than is now available to our numerous brilliant young, and 2) thus become better performers in their own disciplines, not worse, despite diversion of learning time to matter outside the normal coverage of their own disciplines.",
    "There is no better teacher than history in determining the future. There are answers worth billions of dollars in a $30 history book. The same might be said of Poor Charlie’s Almanack. It is the ultimate value investment.",
    "So life is an everlasting battle between those two forces: to get these advantages of scale on one side and a tendency to get a lot like the US Department of Agriculture on the other side, where they just sit around and so forth. I don’t know exactly what they do. However, I do know that they do very little useful work.",
    "The third helpful notion is that it is not enough to think problems through forward. You must also think in reverse, much like the rustic who wanted to know where he was going to die so that he’d never go there. Indeed, many problems can’t be solved forward. And that is why the great algebraist Carl Jacobi so often said, 'Invert, always invert,' and why the Pythagoreans thought in reverse to prove that the square root of two was an irrational number.",
    "It is better to remember the obvious than to grasp the esoteric.",
    "Without numerical fluency , in the part of life most of us inhibit , you are like a one-legged man in an ass-kicking contest.",
    "There’s only one right way to do it: You have to get the main doctrines together and use them as a checklist. And, to repeat for emphasis, you have to pay special attention to combinatorial effects that create lollapalooza consequences.",
    "On the other hand, in copying Jack Welch, I am trying to teach you something. When you don’t know and you don’t have any special competence, don’t be afraid to say so.",
    "When Warren lectures at business schools, he says, 'I could improve your ultimate financial welfare by giving you a ticket with only 20 slots in it so that you had 20 punches, representing all the investments that you got to make in a lifetime. And once you’d punched through the card, you couldn’t make any more investments at all.' He says, 'Under those rules, you’d really think carefully about what you did, and you’d be forced to load up on what you’d really thought about. So you’d do so much better.'",
    "Quickly eliminate the big universe of what not to do; follow up with a fluent, multidisciplinary attack on what remains; then act decisively when, and only when, the right circumstances appear.",
    "It's remarkable how much long-term advantage people like us have gotten by trying to be consistently not stupid, instead of trying to be very intelligent.",
    "If you can get really good at destroying your own wrong ideas, that is a great gift.",
    "I think people who multitask pay a huge price. They think they're being extra productive, and I think when you multitask so much you don't have time to think about anything deeply, you are giving the world an advantage you shouldn't do, and practically everybody is drifting into that mistake.",
    "I'm proud to be associated with the value system at Berkshire Hathaway; I think you'll make more money in the end with good ethics than bad.",
    "Wells Fargo had a glitch - the truth of the matter is they made a business judgement that was wrong. I don't think anything is fundamentally wrong.",
    "The perfect example of Darwinism is what technology has done to businesses.",
    "I regard the bitcoin craze as totally asinine to create some manufactured currency.",
    "I love when I think we're taking territory - if it makes sense in the long term, we just don't give a damn what it looks like in the short term. After all, we're running a cult, not a normal company.",
    "I think time and time again, in reality, psychological notions and economic notions interplay, and the man who doesn't understand both is a damned fool.",
    "Look at this generation, with all of its electronic devices and multitasking. I will confidently predict less success than Warren, who just focused on reading.",
    "I will say this: I know no wise person who doesn't read a lot. I suspect that you can read on the computer now and get a lot of benefit out of it, but I doubt that it'll work as well as reading print worked for me.",
    "When someone takes their existing business and tries to transform it into something else - they fail. In technology that is often the case. Look at Kodak: it was the dominant imaging company in the world. They did fabulously during the great depression, but then wiped out the shareholders because of technological change.",
    "Alternatives to gold and currency and to make a big speculative vehicle - I never considered for one second having anything to do with it. I detested it the minute it had been raised.",
    "They made a mistake. And it was an easy mistake to make. I don't regard setting incentives aggressively as a mistake. I think the mistake was, when the bad news came, they didn't recognize it directly. I don't think that impairs the future of Wells Fargo. They'll be better for it.",
    "There's more honor in investment management than in investment banking.",
    "People are trying to be smart - all I am trying to do is not to be idiotic, but it's harder than most people think.",
    "It is one thing to think gold has some marvelous store of value because man has no way of inventing more gold or getting it very easily, so it has the advantage of rarity. Believe me, man is capable of somehow creating more bitcoin... They tell you there are rules and they can't do it. Don't believe them.",
    "There's danger in just shoveling out money to people who say, 'My life is a little harder than it used to be.' At a certain place you've got to say to the people, 'Suck it in and cope, buddy. Suck it in and cope.'",
    "I'm a great admirer of the Trump change of mind about China and making an ally out of China instead of screaming about their trade.",
    "Gold is a great thing to sew into your garments if you're a Jewish family in Vienna in 1939, but I think civilized people don't buy gold, they invest in productive businesses.",
    "You need a different checklist and different mental models for different companies.",
    "That's the last thing on Earth you should think about... There's just a whole lot of things that aren't going to work for you. Figure out what they are and avoid them like the plague. And one of them is bitcoin... It is total insanity.",
    "A banker who is allowed to borrow money at X and loan it out at X plus Y will just go crazy and do too much of it if the civilization doesn't have rules that prevent it.",
    "I've got some advice for the young: If you've got anything you really want to do, don't wait until you're 93.",
    "If you're unhappy with what you've had over the last 50 years, you have an unfortunate misappraisal of life. It's as good as it gets, and it's very likely to get worse.",
    "It's a real pleasure to earn the trust of your customers slowly over time by doing what's right.",
    "I believe Costco does more for civilization than the Rockefeller Foundation. I think it's a better place. You get a bunch of very intelligent people sitting around trying to do good, I immediately get kind of suspicious and squirm in my seat.",
    "Wall Street has too much wealth and political power.",
    "If you're glued together right and honorable, you will succeed. Get in there and get rid of stupidities and avoid bad people. Try teaching that to your grandchildren. The best way is by example. Fix yourself.",
    "Who would want one's children growing up buying things like bitcoin? I hope to God my family doesn't buy it. It's noxious poison.",
    "I'm used to people with very high IQs knowing how to recognize reality, but there's a huge human tendency where it may be instructive to think that whatever you're doing to succeed is all right.",
    "Economics profession, they've been - they've been confident in various formulas, but economics is not physics. The same formula that works in one decade doesn't work in the next. Economics is a difficult subject.",
    "Most people don't grab the right ideas or don't know what to do with them.",
    "Everyone has the idea of owning good companies. The problem is that they have high prices in relations to assets and earnings, and that takes all of the fun out of the game.",
    "If any successes has come to me, it came because I insisted on thinking things through. That's all I was capable of doing in life, was thinking pretty hard about trying to get the right answer, and then acting on it. I never learned to do anything else.",
    "If all you needed to do is to figure out what company is better than others, everyone would make a lot of money. But that is not the case. They keep raising the prices to the point when the odds change."
];

export default quotes;
