import React, { useState } from 'react';
import './styles.css';

const QuoteSearch = ({ onSearch }) => {
  const [term, setTerm] = useState('');

  const handleSearch = (e) => {
    setTerm(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <div className="quote-search">
      <input
        type="text"
        value={term}
        onChange={handleSearch}
        placeholder="Search for a quote"
        className="search-bar"
      />
    </div>
  );
};

export default QuoteSearch;
