import React, { useState } from 'react';
import QuoteSearch from './QuoteSearch';
import QuoteList from './QuoteList';
import Footer from './Footer';
import quotes from './quotes';
import './styles.css';

const App = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const filteredQuotes = quotes.filter(quote =>
    quote.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="app">
      <header className="header">
        <img src="/images/munger.jpg" alt="Charlie Munger" className="charlie-img" />
        <h2 style={{ color: '#f0f0f0' }}>What do you want help with? AA</h2>
        <p className="tagline"><i>Charlie’s here to help (but "lower your expectations")</i></p>
        <QuoteSearch onSearch={handleSearch} />
      </header>
      {searchTerm && <QuoteList quotes={filteredQuotes} />}
      <Footer />
    </div>
  );
};

export default App;
