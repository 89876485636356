import React from 'react';
import './styles.css';

const QuoteList = ({ quotes }) => {
  return (
    <div className="quote-list">
      {quotes.map((quote, index) => (
        <div className="quote" key={index}>
          <img src="/images/munger.jpg" alt="Charlie Munger" className="quote-img" />
          <div className="quote-text">{quote}</div>
        </div>
      ))}
    </div>
  );
};

export default QuoteList;
